import React, { useContext, useState } from 'react';
import { useLocation } from '@reach/router';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import useClipboard from "react-use-clipboard";

import useForm, { DEFAULT_FIELD } from '../../hooks/useForm';
import useDBList from '../../hooks/useDBList';
import ButtonWithLoading from '../../components/Form/inputs/ButtonWithLoading';
import Task, { validator as taskValidator } from './Form/inputs/Task';
import { FirebaseContext } from '../../app/components/Firebase';
import TextField from '@material-ui/core/TextField';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Button from '@material-ui/core/Button';

const translations = {
  ui: {
    buttons: {
      submit: {
        label: 'Add',
      },
    },
  },
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1),
  },
  errorContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1),
    backgroundColor: '#f88'
  },
  formFooter: {
    margin: theme.spacing(2),
  },
  formButtons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  fields: {
    margin: theme.spacing(1),
    width: 300,
  },
  error: {
    backgroundColor: theme.palette.error.light,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    borderRadius: 3,
  },
}));

const validators = {
  task: taskValidator,
}

// const createTest = async ( {email, clientTimestamp} ) => {
//   const data = {
//     email,
//     clientTimestamp,
//   }
//   const url = '/network/api/tests';
//   const response = await fetch(url, {
//     method: 'POST',
//     mode: 'cors',
//     cache: 'no-cache',
//     credentials: 'same-origin',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     referrerPolicy: 'no-referrer', 
//     body: JSON.stringify(data) 
//   })
//   return response.json();
// }

const submitTask = ({ event, inputs, props, setInputs, setSuccess }) => {
  const { task } = inputs.fields;
  const addTask = props.addTask;
  return addTask({ name: task.value, clientTimestamp: new Date() })
  .then(async (data) => {
    setInputs(nextInputs => ({
      ...nextInputs,
      fields: {
        ...nextInputs.fields,
        task: DEFAULT_FIELD,
      },
    }));
    return ({
      result: {},
    });
  }).catch((error) => {
    console.log('error', error);
    props.setError(error);
    return ({
      errors: error,
      result: {},
    });
  })
}

// const start = (submitted, testConfig) => (event) => {
//   const { url, regions, startRegion } = testConfig;
//   window.location.href = `${url}?uid=${submitted}&tests=${regions}&region=${startRegion}`;
// };

const DbTestForm = (props) => {
  const [tasks, setTasks] = useState([])
 
  const firebase = useContext(FirebaseContext);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const msg = (params.get('msg') === 'Completed');
  const [submitted] = useState();
  // const [testConfig, setTestConfig] = useState();
  const [error, setError] = useState();

  const classes = useStyles();
  const {
    addElement: addTask,
    deleteElement: deleteTask,
  } = useDBList(firebase, 'support/todolist/tasks', tasks, setTasks);
  const {
    inputs,
    handleInputChange,
    handleSubmit,
    handleFocus,
    handleBlur,
    loading,
    success,
  } = useForm({...props, addTask, setError}, submitTask, {
    task: DEFAULT_FIELD,
  }, validators);
  const [ setCopied] = useClipboard(error);
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item md={6}>
        <Paper>
        {msg &&
          <Box p={3}>
            <Typography gutterBottom variant="h2" component="h1" align="center">Test completed. Thanks <span role="img" aria-label="smiling face emoji">🙂</span></Typography>
          </Box>
        }
        {!msg && !submitted && 
          <React.Fragment>
            <Typography gutterBottom variant="h2" component="h1" align="center">Task List test</Typography>
            <Typography gutterBottom variant="body2" align="center">version 1.0</Typography>

            <Box p={3}>
              <Typography gutterBottom variant="body1">
              The task list test is intended to check db functionality in the app.
              </Typography>
            </Box>
            <form className={classes.container}>
              <Task
                handleInputChange={handleInputChange}
                handleBlur={handleBlur}
                handleFocus={handleFocus}
                inputs={inputs}
                className={classes.fields}
              />
              <Box className={classes.formButtons} p={3}>
                <ButtonWithLoading
                  color="primary"
                  onClick={event => handleSubmit(event, inputs)}
                  disabled={loading}
                  loading={loading} success={success}
                  label={translations.ui.buttons.submit.label} />
              </Box>
            </form>
            {error &&
                <Box p={2} className={classes.errorContainer}>
                  <Typography variant="h5" gutterBottom>Problem detected</Typography>
                  <TextField helperText="Pleas send this error data" fullWidth defaultValue={error} rows="10" multiline />
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={setCopied}
                    startIcon={<FileCopyIcon />}
                  >
                    Copy error text to clipboard
                  </Button>
                </Box>              
            }
          </React.Fragment>

        }
        <Grid container>
        <Grid item xs={12}>
          <List dense tasks={tasks}>
            {tasks.map((task, index) =>
              <ListItem key={`${task.id}`}>
                <ListItemText
                  primary={task.data.name}
                  secondary={task.data.clientTimestamp.toString()}
                />
                <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={() => deleteTask(task.id)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            )}
          </List>
        </Grid>
      </Grid>

        </Paper>
      </Grid>
    </Grid>
  );
}

export default DbTestForm;
