import React from 'react';
import TextField from '@material-ui/core/TextField';
import validate from 'validate.js';

const FIELD = 'task';

const translations = {
  placeHolder: 'Task',
  validationError: 'Task should not be emtpy',
}

export const validator = (value, inputs) => {
  // console.log('validator', value)
  const result = validate(
    {
      [FIELD]: value
    }, 
    { 
      [FIELD]: {
        presence: {allowEmpty: false},
        format: {
          pattern: '.*',
          message: translations.validationError,
        }
      },
    },
  )
  const errorMessage = (result && result[FIELD]) ? result[FIELD].join(' ') : null;
  return { valid: (result === undefined), error: errorMessage }
};

const Task = ({ handleInputChange, handleFocus, handleBlur, inputs, ...props }) => (
  <TextField
    id={FIELD}
    placeholder={translations.placeHolder}
    onChange={handleInputChange(FIELD)}
    onBlur={handleBlur(FIELD)}
    onFocus={handleFocus(FIELD)}
    value={inputs.fields[FIELD].value}
    error={!!inputs.fields[FIELD].error}
    helperText={inputs.fields[FIELD].error}
    required={inputs.fields[FIELD].required}
    {...props}
  />
);

export default Task;
