import { useEffect } from 'react';

const useDBList = (firebase, refString, elements, setElements) => {
  let db;
  let ref;
  if (firebase) {
    db = firebase.db;
    ref = db.collection(refString);
  }
  useEffect(() => {
    let unsubscribe = () => {}
    if (firebase) {
      ref
        .onSnapshot((querySnapshot) => {
          const list = [];
          querySnapshot.forEach((doc) => {
            list.push({id: doc.id, data: doc.data()});
          })
          setElements(list);
        });
    }
    return unsubscribe;
  }, [firebase, ref, setElements]);
  const addElement = async (task) => {
    return await ref.add(task);
  }
  const deleteElement = async (id) => {
    return await ref.doc(id).delete();
  };
  return { addElement, deleteElement };
};

export default useDBList;
