import React from 'react';
import Layout from '../../components/Layout';
import TopBar from '../../components/TopBar';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import DBTestForm from '../../components/DBTestForm';
import Helmet from 'react-helmet';

const DBTestPage = (props) => {
  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <TopBar title="Database connectivity test" />
      <Grid container spacing={3} justifyContent="center" >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box p={2}>
            <DBTestForm {...props} />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default DBTestPage;
